import { getOrderStatus, PollOrderStatusResponse } from './get-order-status';

const POLL_INTERVAL = 1000;
const POLL_TIMEOUT = 60 * 1000;

export async function pollOrderStatus(
  identifier: string,
  timeout?: number
): Promise<PollOrderStatusResponse | Pick<PollOrderStatusResponse, 'status'>> {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      const response = await getOrderStatus(identifier);
      if (response.status === 'SUCCESSFUL') {
        clearInterval(interval);
        resolve(response);
      }
      if (response.status === 'FAILED') {
        clearInterval(interval);
        reject(new Error(`Order Failed`));
      }
    }, POLL_INTERVAL);
    setTimeout(() => {
      clearInterval(interval);
      resolve({ status: 'PROCESSING' });
    }, timeout ?? POLL_TIMEOUT);
  });
}
