import { useContext, useEffect, useState, useRef } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { ASK, OBSERVE } from '../constants';
import {
  getProductCatalogFromMagpie,
  previewPrice,
  getCreationPayload,
  sendAsyncOrderToMagpie,
} from '../../../utils/api';
import { LookupContext } from '../../../contexts/LookupContext';
import { doesCountryRequireState } from '../../../utils/countries';
import { useToasts } from 'react-toast-notifications';
import { BillingAddress } from './BillingAddress';
import { Cost } from './Cost';
import {
  SubscriptionsCommonFields,
  PaymentTerms,
} from './SubscriptionsCommonFields';
import { Subscriptions } from './SlpSubscriptions';
import { Notes } from './Notes';
import { LoadingDialog } from '../LoadingDialog/index';
import { defaultManualBillingPeriod } from '../../../domains/magpie/models';
import { isFreePlan } from '../../../utils/getFreePlans';
import { getProductCatalogVersion } from '../../../domains/accounts/utils/getProductCatalogVersion';
import { pollOrderStatus } from '../../../domains/magpie/api/poll-order-status';

export const SubscriptionCreationModal = ({
  onCloseModal,
  props: modalProps,
}) => {
  const [productsCatalog, setPlans] = useState([]);
  const [nonChargeableSkus, setNonChargeableSkus] = useState([]);
  const [formData, setFormData] = useState({
    addressLine1: '',
    addressLine2: '',
    country: '',
    city: '',
    state: '',
    county: '',
    zipCode: '',
    companyName: '',
    taxNumber: '',
    purchaseOrderNumber: '',
    invoiceNotes: '',
    billingPeriod: defaultManualBillingPeriod,
    subscriptions: [],
    subTotal: 0.0,
    discountPercentage: 0.0,
    taxPercentage: 0.0,
    total: 0.0,
    paymentTerm: PaymentTerms[0].value,
  });

  const { addToast } = useToasts();

  const context = useContext(LookupContext);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isPreviewLoading, setIsPreviewLoading] = useState(false);

  const previewCostsAbortRequestController = useRef();

  const { currency, id: accountId } = context?.account;

  const isDataReadyForSubmission = () => {
    const { companyName, country } = formData;
    const hasAllSitesBasic = formData.subscriptions.every((subscription) =>
      subscription.allowances.every(
        (allowance) => allowance.planName === 'Basic'
      )
    );
    const hasUnfilledSite = formData.subscriptions.some(
      (subscription) => !subscription.site
    );

    // Check all required fields
    if (
      !formData.subscriptions.length ||
      hasAllSitesBasic ||
      hasUnfilledSite ||
      !companyName ||
      !country ||
      (doesCountryRequireState(country) && !formData.state)
    ) {
      return false;
    }
    return true;
  };

  const removeUnneededFormFields = () => {
    // Remove unneeded data
    const { subTotal, taxPercentage, total, ...rest } = formData;
    return rest;
  };

  useEffect(() => {
    if (!accountId || !formData.billingPeriod || !currency || !context?.account)
      return;

    const getPlans = async () => {
      try {
        const plansRes = await getProductCatalogFromMagpie(
          formData.billingPeriod.toLowerCase(),
          currency,
          getProductCatalogVersion(context?.account)
        );
        const plansData = await plansRes.json();

        const productsCatalog = plansData
          .map((product) =>
            product.plans.map((plan) =>
              plan.allowances.map((allowance) => {
                return {
                  sku: allowance.sku,
                  allowance,
                  planName: plan.plan_name,
                  productName: product.product_name,
                };
              })
            )
          )
          .flat(2);
        setPlans(productsCatalog);
        setNonChargeableSkus(
          productsCatalog.filter((pc) => isFreePlan(pc)).map((pc) => pc.sku)
        );
      } catch (e) {
        console.log(e);
      }
    };
    getPlans();
  }, [formData.billingPeriod, currency, accountId, context?.account]);

  useEffect(() => {
    if (!isDataReadyForSubmission()) {
      return;
    }

    setIsPreviewLoading(true);

    // Cancel the old request. We don't want to have parallel preview requests.
    previewCostsAbortRequestController.current?.abort();

    const abortController = new AbortController();
    previewCostsAbortRequestController.current = abortController;
    const previewCosts = async () => {
      const payload = getCreationPayload(
        accountId,
        {
          ...removeUnneededFormFields(),
          user: {
            firstName: context.user.name,
            lastName: context.user.name,
            emailAddress: context.user.email,
          },
        },
        context.sites,
        nonChargeableSkus
      );
      const rawResponse = await previewPrice(payload, abortController.signal);

      setIsPreviewLoading(false);

      const response = await rawResponse.json();
      if (!rawResponse.ok) {
        const errorMessage = response?.message ?? rawResponse.status;
        addToast(`Preview error: ${errorMessage}`, {
          appearance: 'error',
          autoDismiss: true,
        });
        console.error('Order', errorMessage);
      }

      const {
        amount_without_tax: subTotal,
        amount_with_tax: total,
        tax_rate: taxPercentage,
      } = response.pay_now;

      setFormData({
        ...formData,
        subTotal,
        taxPercentage,
        total,
      });
    };

    previewCosts();

    // We don't need to add the while formData object as a dependency. It will keep calling this as we are setting the formData at the end
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modalProps,
    context.user,
    formData.state,
    formData.country,
    formData.taxNumber,
    formData.companyName,
    formData.billingPeriod,
    formData.subscriptions,
    formData.discountPercentage,
  ]);

  if (!modalProps) {
    return null;
  }

  const handleUpdateField = (event) => {
    setFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubscriptionChange = (event, index, oldAllowance) => {
    const updatedSubscriptions = [...formData.subscriptions];
    const eventId = event.target.id;
    const eventValue = event.target.value;
    const isPlanChange = eventId === 'plan';
    const currentSubscription = formData.subscriptions[index];
    let allowances = currentSubscription.allowances;

    if (isPlanChange) {
      allowances = [
        ...currentSubscription.allowances,
        {
          ...productsCatalog.find((product) => product.sku === eventValue),
        },
      ];

      // Remove the old one
      if (allowances.length === 3) {
        const indexOfAllowanceToRemove = allowances.findIndex(
          (allowance) => allowance.sku === oldAllowance.sku
        );
        allowances.splice(indexOfAllowanceToRemove, 1);
      }
    } else {
      updatedSubscriptions[index][eventId] = eventValue;
    }

    updatedSubscriptions[index] = {
      ...currentSubscription,
      allowances,
    };

    setFormData({
      ...formData,
      subscriptions: updatedSubscriptions,
    });
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payload = getCreationPayload(
      accountId,
      {
        ...removeUnneededFormFields(),
        ...{
          additionalEmailAddresses: context.account.email_recipients,
          user: {
            firstName: context.user.name,
            lastName: context.user.name,
            emailAddress: context.user.email,
          },
        },
      },
      context.sites,
      nonChargeableSkus
    );

    try {
      const rawResponse = await sendAsyncOrderToMagpie(payload);
      const response = await rawResponse.json();
      const { identifier } = response;
      const timeout = 180 * 1000; // 3 minutes
      const pollResponse = await pollOrderStatus(identifier, timeout);
      const { status } = pollResponse;
      if (status === 'SUCCESSFUL') {
        addToast('Order submitted successfully. Please reload the page.', {
          appearance: 'success',
          autoDismiss: false,
        });
        window.location.reload();
      } else {
        setIsSubmitting(false);
        addToast(
          'Order is taking too long to process. Please come back later to check if it succeeded.',
          { appearance: 'warning', autoDismiss: false }
        );
      }
    } catch (error) {
      setIsSubmitting(false);
      addToast('Order error', { appearance: 'error', autoDismiss: true });
      console.error('Order', error);
    }
  };

  const addNewSubscription = () => {
    const initialSelectedPlans = [
      productsCatalog.find(
        (pc) =>
          pc.productName === ASK &&
          pc.allowance.self_serve_enabled &&
          !pc.allowance.chargeable
      ),
      productsCatalog.find(
        (pc) =>
          pc.productName === OBSERVE &&
          pc.allowance.self_serve_enabled &&
          !pc.allowance.chargeable
      ),
    ];

    setFormData({
      ...formData,
      subscriptions: [
        ...formData.subscriptions,
        {
          id: `subscription-${new Date().getTime()}`,
          organization: '',
          site: '',
          allowances: initialSelectedPlans,
        },
      ],
    });
  };

  const removeSubscriptionFromList = (index) => {
    const updatedFormData = {
      ...formData,
      subscriptions: [...formData.subscriptions],
    };
    updatedFormData.subscriptions.splice(index, 1);
    setFormData(updatedFormData);
  };

  if (isSubmitting) {
    return <LoadingDialog title="Creating Subscription" />;
  }

  return (
    <Dialog
      fullWidth
      maxWidth="lg"
      open
      onClose={onCloseModal}
      disableEnforceFocus
    >
      <DialogTitle>Create Subscription for Account {accountId}</DialogTitle>
      <DialogContent>
        <Grid container spacing={10}>
          <Grid item xs={6}>
            <BillingAddress
              formData={formData}
              handleUpdateField={handleUpdateField}
            />
          </Grid>
          <Grid item xs={6}>
            <Cost
              subTotal={formData.subTotal}
              taxPercentage={formData.taxPercentage}
              total={formData.total}
              handleUpdateField={handleUpdateField}
              isPreviewLoading={isPreviewLoading}
              currency={currency}
            />
            <Notes
              invoiceNotes={formData.invoiceNotes}
              handleUpdateField={handleUpdateField}
              textFieldRows={3}
            />
          </Grid>
        </Grid>

        <h2>Subscriptions</h2>
        <SubscriptionsCommonFields
          formData={formData}
          handleUpdateField={handleUpdateField}
          paymentTerms={PaymentTerms}
          isCreationMode
          allowBillingPeriodSelection
        />

        <Subscriptions
          formData={formData}
          addNewSubscription={addNewSubscription}
          removeSubscriptionFromList={removeSubscriptionFromList}
          handleSubscriptionChange={handleSubscriptionChange}
          organizations={context.organizations}
          sites={context.sites}
          productsCatalog={productsCatalog}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModal}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          disabled={isPreviewLoading || !isDataReadyForSubmission()}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionCreationModal;
